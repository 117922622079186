import React from 'react'
import SEO from "../components/seo"
import LayoutsComponent from '../layouts/LayoutsComponent';
// import VerificacionCorreoCmp from '../components/pages/Verificaciones';
import VerificacionTelefonica from '../components/pages/afi/VerificacionTelefonica'

class VerificacionCorreo extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        // let infoLayer={
        //     title: 'Progreso de la verificación de correo',
        //     operation: 'VerifyEmail'
        // }
        const { pageContext, location } = this.props
        console.log("pageContext=>",pageContext.locale)
        return (
            <LayoutsComponent
                layout={pageContext.layout}
                broker={pageContext.broker}
                companyName={pageContext.company}
                pageContext={pageContext}
                location={location}
                render={() => (
                    <>
                        <SEO title={pageContext.title} />
                        <VerificacionTelefonica 
                            companyName={pageContext.company} 
                            pageContext={pageContext}  
                            title={pageContext.locale ==="esES" ? "Verificación de Correo": "Email verification"}
                            parametro="correo"
                            leyenda="Este es tu correo?"
                        />
                        {/* <VerificacionCorreoCmp pageContext={pageContext} data={infoLayer}/> */}
                    </>
                )}
            />
        )
    }
}

export default VerificacionCorreo;
